@import '../../../../assets/styles/variables';

.content {
  margin-top: 106px;

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 275px;
  }

  &__title {
    width: 275px;
    margin-bottom: 112px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: $main-title;
    text-align: center;
  }

  &__image {
    margin-bottom: 118px;
  }
}

@media(min-height:568px) and (max-height: 640px) and (orientation: portrait) {
  .content {
    margin-top: 25px;

    &__title {
      margin-bottom: 50px;
    }

    &__image {
      margin-bottom: 50px;
    }
  }
}

@media (min-height: 641px) and (max-height: 811px) and (orientation: portrait) {
  .content {
    margin-top: 35px;

    &__title {
      margin-bottom: 60px;
    }

    &__image {
      margin-bottom: 80px;
    }
  }
}

@media (max-height: 767px) and (orientation: landscape) {
  .content {
    margin-top: unset;

    &__title {
      margin-bottom: 20px;
    }

    &__image {
      margin-bottom: 20px;
    }

    &__button {
      margin-bottom: 20px;
    }
  }
}