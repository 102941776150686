@import '../../../../assets//styles/variables.scss';

.container {
  width: 275px;
  margin: 0 auto;
}

.button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

}

.form {
  margin: 0 auto;
  width: 330px;
  text-align: center;

  &__hidden {
    margin-top: 40px;
  }

  &__ipn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__label {
    margin-right: 8px;
    padding-top: 6px;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: $wog-header;
  }

  &__input {
    width: 165px;
    font-size: 22px !important;
    line-height: 24px !important;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.help {
  position: fixed;
  top: 20px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media(max-height: 568px) {
  .button {
    &__down {
      bottom: unset;

    }
  }
}

@media(min-height: 568px) and (max-height: 667px) {
  .button {
    padding: 10px;
  }
}

@media(min-height: 668px) {
  .button {
    bottom: 47px;
  }
}
