@import '../../assets/styles/variables.scss';

.input-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 283px;
    font-family: 'Montserrat', sans-serif;

    &__label {
        margin-bottom: 6px !important;
        padding: 0 20px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: $text;
    }
}

@media(max-height: 569px) {
    .input-text {
        input[type='text'] {
            height: 25px;
        }
    }
}

@media(min-height: 569px) and(max-height: 670px) {
    .input-text {
        input[type='text'] {
            height: 25px;
        }
    }
}

@media(min-height: 671px) and(max-height: 800px) {
    .input-text {
        input[type='text'] {
            height: 30px;
        }
    }
}

// @media(min-width: 320px) and (max-width: 360px) and (orientation: portrait) {
//     .input-text {
//         input[type='text'] {
//             height: 20px;
//         }
//     }
// }

// @media(min-width: 361px) and (max-width: 400px) and (orientation: portrait) {
//     .input-text {
//         input[type='text'] {
//             height: 25px;
//         }
//     }
// }

// @media(min-width: 401px) and (max-width: 420px) and (orientation: portrait) {
//     .input-text {
//         input[type='text'] {
//             height: 30px;
//         }
//     }
// }
