@import '../../assets/styles/variables.scss';

.label {
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: $wog-label;
  text-transform: uppercase;
}

.inputDefault {
  input {
    border: 1px $white solid;
    padding-left: 5px;
    height: 4vh;
    max-height: 4vh;
    min-height: 40px;
    font-size: 22px;
    line-height: 24px;
  }
}

.errorInput {
  input {
    padding-left: 5px;
    border-bottom: 1px $color-red solid;
    height: 4vh;
    max-height: 4vh;
    min-height: 40px;
    font-size: 22px;
    line-height: 24px;
  }
}
