@import '../../../../assets/styles/variables.scss';

.declined {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__description {
        margin: 120px auto 30px;
        width: 275px;
        text-align: center;

        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: $wog-declined-text;
    }

    &__button {
        position: absolute;
        bottom: 46px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
