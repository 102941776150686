.button_submit {
  width: 283px;
}

.button {
  font-family: 'Montserrat', Arial, sans-serif;
  height: 47px;
  background: #c964cf;
  border-radius: 30px;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  text-transform: capitalize;
  ;
}

.button:disabled {
  background: #74a388;
  cursor: unset;
}

.button:hover {
  transition: background-color 0.5s ease-in-out;
  background-color: #ca9bcc;
}