@import '../../../../assets/styles/variables';

.content {
  margin-top: 106px;

  &__canceled {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 275px;
  }

  &__title {
    width: 275px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: $main-title;
    text-align: center;
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin: 12px 0 73px 0;
  }

  &__image {
    margin-bottom: 50px;
  }

  &__text {
    margin-bottom: 24px;
    font-size: 14px;
    color: $main-title;
  }

  &__phone {
    font-weight: 700;
    font-size: 20px;
    color: $pink;
  }
}

@media(max-height: 568px) and (orientation: portrait) {
  .content {
    margin-top: 50px;

    &__image {
      width: 90%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-height: 767px) and (orientation: landscape) {
  .content {
    margin-top: unset;

    &__title {
      margin-bottom: 20px;
    }

    &__image {
      margin-bottom: 20px;
    }

    &__button {
      margin-bottom: 20px;
    }
  }
}