@import '../../../../assets/styles/variables.scss';

.payment__accepted {
    margin: 156px auto 0;
    width: 275px;

    &__title {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;

        & span {
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: $wog-header;
            margin-bottom: 20px;

            & span {
                color: $wog-declined-text;
            }
        }

    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button {
        position: absolute;
        bottom: 46px;
        left: 50%;
        transform: translateX(-50%)
    }

}
