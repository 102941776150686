@import '../../../../assets/styles/variables.scss';

.payment__cancel {
    margin: 156px auto 0;

    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        & span {
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: $wog-declined-text;
        }
    }

    &__text{
        text-align: center;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button {
        position: absolute;
        bottom: 46px;
        left: 50%;
        transform: translateX(-50%);
    }
}
