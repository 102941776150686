.container {
    width: 275px;
    margin: 0 auto;
}

.button {
    position: absolute;
    bottom: 46px;
    left: 50%;
    transform: translateX(-50%);
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}
.help {
    position: fixed;
    top: 20px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}