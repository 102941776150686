@import '../../../assets/styles/variables.scss';

.button {
  // width: 100%;
  width: 335px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: $wog-header-light;

  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: $white;

  cursor: pointer;
}

.button:disabled {
  background: $wog-disable-button;
  cursor: unset;
}

@media(min-width: 320px) and (max-width: 360px) {
  .button {
    width: 300px;
  }
}
