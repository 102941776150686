@import '../../assets//styles/variables.scss';

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 7vh auto 0;
  padding: 0 10px;

  &__title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: $wog-header;
  }

  &__list {
    list-style: none;
    margin-top: 4vh;
    padding: 0;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border: 2px solid $wog-header;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
  }

  &__item {
    display: flex;
    height: 60px;
    margin-bottom: 3vh;
    color: $wog-header;
  }

}

.text {

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 400;

  &__bigger {
    font-size: 36px;
    line-height: 24px;

    & span {
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &__smaller {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.currency {
  width: 9px;
  height: 16px;
}
