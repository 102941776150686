$header-background: #EFEFEF;
$main-title: #FFFFFF;
$text: #FFFFFF;
$pink: #C964CF;
$color-text-notification-description: #3e4871;

//wog
$wog-header: #02593D;
$wog-header-light: #00A650;
$wog-text: #373A36;
$color-red: #ee1e4e;
$color-main: #94c347;
$white: #FFFFFF;
$black: #000000;
$wog-disable-button: #74a388;
$wog-title: #333333;
$wog-label: 828282;
$wog-declined-text: #AD0101;
$wog-payment-header: #504E58;
$wog-payment-span: #54525D;
$wog-rules-background: #E5E5E5;
//main page
$main-page-background: #009345;
