@import '../../assets/styles/variables.scss';

.upload {
  display: flex;
  flex-direction: column;
  margin: 32px 0 10px;
  color: $wog-title;

  &__form {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 153px;
    height: 47px;
    background: $white;
    border: 1px solid $wog-header;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
  }

  &__input {
    display: none;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 0 5px;
    box-sizing: border-box;

    & span {
      height: 24px;
      box-sizing: border-box;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
  }

  &__main {
    text-align: center;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: $text;
    text-transform: uppercase;
  }

  &__error {
    font-size: 12px;
    line-height: 24px;
    padding-right: 12px;
    margin-left: auto;
  }

  &__image {
    margin: 0 auto;
    position: relative;
    backface-visibility: hidden;
    transition: .5s ease;

    & img {
      opacity: 1;
      max-width: 289px !important;
      max-height: 191px !important;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &:hover img {
      opacity: 0.3;
      // background-color: rgba(#000000, 0.5);
    }

    &:hover .overlay {
      // background-color: rgba(#000000, 1);
      opacity: 1;
    }
  }

  & .overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

    &__button {
      cursor: pointer;
      background-color: $wog-header;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      & input[type="file"] {
        opacity: 0;
      }
    }

    &__label {
      width: 37px;
      height: 37px;
      cursor: pointer;
    }
  }

  &__name {
    width: 200px;
    margin: 10px auto 0;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
    text-decoration-line: underline;
    color: $text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.smaller {
  width: 37px;
  height: 37px;
}
