@import '../../../../assets/styles/variables';

.form {

  &-header {
    width: 100vw;
    //background-color: $header-background;
    padding: 24px 0 24px 0;

    &__title,
    &__description {
      display: block;
      text-align: center;
      color: $main-title
    }

    &__title {
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: 700;
    }

    &__description {
      font-size: 14px;
    }
  }

  &__button {
    //margin-top: 20px;
    margin-bottom: 15px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  &__button-container {
    max-width: 222px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0 auto;
    // margin-bottom: 46px;
  }

  &__title-cap {
    margin-bottom: 16px;
  }

  &__check-container {
    margin-bottom: 27px;
    margin-left: 5%;
  }

  &_disable {
    pointer-events: none;
  }

}

.support-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
}

.support-title {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.support-number {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #C964CF;
}

.text_item_title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  max-width: 275px;
  width: 100%;
  font-size: 14px;
  color: rgb(55, 58, 54);
  line-height: 26px;
  letter-spacing: 1px;
  margin-bottom: 16px;
}

.input-amount {
  max-width: 283px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 13px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #828282;
  outline: none;
  border: none;
  cursor: pointer;

  &__error {
    height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #ad0101;
    margin: 0 auto;
    text-align: center;
    display: block;
    visibility: hidden;
    //margin-bottom: 16px;
  }
}

.link {
  color: #333333;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.underline {
  text-decoration: underline;
  margin: 0 auto;
  text-align: center;
}

.text_item_subtitle {
  color: #263238;
  max-width: 218px;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  margin-right: unset;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: lowercase;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.visible {
  visibility: visible;
}

.default {
  width: 62px;
  border-radius: 20px;
  cursor: pointer;
}

.button:disabled {
  background: #74a388;
  cursor: initial;
}

.loader {
  visibility: hidden;
  position: absolute;
  top: 80px;
  left: 115px;
  z-index: 10;
  margin-bottom: 5%;
}

.loader_visible {
  visibility: visible;
}

.empty-error-span {
  height: 20px;
}

@media(min-width: 320px) and (max-width: 360px) and (orientation: portrait){
  .form {
    margin-top: 5px;

    &-header {
      padding: 10px 0 10px 0;

      &__title {
        font-size: 18px;
      }
    }

    &__button {
      margin: 5px 0 5px 0;
    }
  }
}

@media(min-width: 361px) and (max-width: 400px) and (max-height: 800px) and (orientation: portrait) {
  .form {
    margin-top: 10px;

    &-header {
      padding: 15px 0 15px 0;

      &__title {
        font-size: 20px;
      }
    }

    &__button {
      margin: 5px 0 5px 0;
    }
  }
}
