@import '../../../../assets/styles/variables.scss';

.payment {
    margin: 68px auto 0;
    width: 290px;


    &__help {
        position: fixed;
        top: 20px;
        right: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    }

    &__header {
        width: 275px;
        margin: 20px auto 0;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: $wog-payment-header;
        letter-spacing: -0.3px;
        text-align: center;
    }

    &__range {
        margin-top: 50px;

        &__title {
            margin-bottom: 5px;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            color: $wog-label;
            text-transform: uppercase;
        }

        &__input {
            margin-top: 40px;
        }

        &__description {
            width: 289px;
            display: flex;
            justify-content: space-between;

            & span {
                font-size: 14px;
                line-height: 24px;
                color: $wog-payment-span;
            }
        }
    }

    &__button {
        position: absolute;
        bottom: 46px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__description {
        margin: 16px auto 37px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__item {
        display: flex;
        width: 250px;
        justify-content: space-between;
        align-items: flex-end;
        // margin-bottom: 16px;

        & span {
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
        }
    }

    &__title {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.3px;
        text-transform: uppercase;

        color: $wog-payment-span;
    }

    &__declined {
        margin-top: 26px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: $wog-declined-text;

        & div {
            margin-bottom: 20px;
        }
    }

    &__currency {
        width: 8px;
        height: 10px;
        stroke: $wog-text;
    }

    &__wasted {
        width: 100px;
        text-align: center;
        font-weight: bold !important;
        font-size: 18px;
        line-height: 21px;
        color: $wog-declined-text;
    }

}

.text {

    &__input {
        width: 100px !important;

        & input {
            padding: 0;
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: $wog-header;
        }

    }

    &__label {
        display: none;
    }
}

.hidden {
    display: none;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

@media(max-height: 600px) {
    .payment {
        &__button {
            position: relative;
            display: inline-flex;
            padding: 100px 0 20px 0;
            margin: 0 auto;
        }
    }
}
