@import '../../assets/styles/variables.scss';

.offer {
    display: flex;
    align-items: center;
    width: 289px;
    // margin: auto;

    &__text {

        margin-left: 4px;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;

        & > a {
            color: $wog-header;
            font-weight: 700;
        }
    }
}
