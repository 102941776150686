@import '../../../../assets//styles//variables.scss';

.info {
  margin-top: 68px;

  &__help {
    position: fixed;
    top: 20px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__header {
    margin-bottom: 26px;
    width: 275px;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: $wog-header;
    text-align: center;
  }


  &__list {
    width: 280px;
    margin: 0 auto 0;
  }

  &__item {
    display: flex;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 19px;
    color: $main-title;
  }

  &__icon {
    margin-right: 7px;
  }

  &__description {
    & span {
      font-weight: 700;
      color: $wog-header;
    }
  }

  &__button {
    position: absolute;
    bottom: 47px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__svg {
    & svg {
      fill: $wog-header;
    }
  }
}
