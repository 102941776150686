@import '../../assets/styles/variables.scss';

.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  &__status {
    background-color: $main-page-background;
    color: $white;
    height: 56px;
  }

  //&__background {
  //  background: url('src/fon.jpg');
  //}
}

