@import '../../../../assets/styles/variables.scss';

.accepted {
    margin: 156px auto 0;
    width: 300px;

    &__description {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: $wog-header;
        text-align: center;
        margin-bottom: 30px;

        & span {
            display: block;
            margin-bottom: 21px;
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button {
        position: absolute;
        bottom: 46px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}
