.header {
  position: absolute;
  bottom: 20px;
  width: 100%;

  &__inner {
    padding: 32px 0 33px 0;
    position: relative;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close {
    position: absolute;
    right: 37px;
    cursor: pointer;
  }
}

@media(min-width: 320px) and (max-width: 360px) {
  .header {
    &__inner {
      padding: 10px 0 10px 0;
    }
  }
}
@media(min-width: 360px) and (max-width: 400px) {
  .header {
    &__inner {
      padding: 15px 0 15px 0;
    }
  }
}
