@import '../../../../assets/styles/variables.scss';

.main {
    &__accordion {
        width: 100%;
        height: 100vh;
        background: $wog-rules-background;
    }

    &__title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $wog-payment-header;
    }

    &__top__description {
        margin-bottom: 4px;
        border-bottom: 1px solid $wog-header;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $wog-payment-header;
    }

    &__bottom__description {
        font-family: Noto Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $wog-payment-span;
    }

    &__button {
        position: fixed;
        bottom: 15px;
        right: 15px;
        z-index: 99;
        cursor: pointer;
    }

}
